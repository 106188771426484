import { useMediaSearch } from "../../../hooks/useMediaSearch";
import { MediaSearchQuery } from "@jconradi/tutu-porn-api-client/src/media";
import { TutuModelVideo } from "@jconradi/tutu-porn-components/services/api/tutu-models/tutu-models";
import { BuySpot } from "../../Ads/Tutu/BuySpot";
import { TutuMediaGridItem } from "../../Ads/TutuModel/TutuMediaGridItem";
import { Spinner } from "../../Spinner/Spinner";
import { MediaGridItem } from "./MediaGridItem";
import React, { useEffect, useId } from 'react';
import RandomSeed from 'seedrandom';

export interface MediaGridProps {
    query: MediaSearchQuery;
    loading: boolean;
    className?: string;
    tutuAd?: TutuModelVideo;
    ads?: boolean;
    hrefPrefix?: string;
}
export const MediaGrid = ({ query, loading, className, tutuAd, ads = true, hrefPrefix  }: MediaGridProps) => {
    const mediaQuery = useMediaSearch(query, loading);

    const rand = RandomSeed(JSON.stringify(query));
    const val = rand();

    const insertPosition = mediaQuery.isLoading ? -1 : Math.floor(val * (mediaQuery.data!.media.length + 1));
    const tutuAdPosition = mediaQuery.isLoading ? -1 : Math.floor(rand() * (mediaQuery.data!.media.length + 1));

    return (
        <div className="lg:ml-4 grid grid-cols-2 min-[480px]:grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 gap-2 flex-1 w-full">
            {mediaQuery.isLoading && <Spinner className="mx-auto col-span-full" /> }
            {mediaQuery.data?.media.map((mediaListItem, idx) => {
                return (
                    <React.Fragment key={mediaListItem.slug}>
                    {idx == insertPosition && ads &&
                        <BuySpot className="w-full h-full text-center" />
                    }
                    {idx == tutuAdPosition && tutuAd && ads &&
                        <TutuMediaGridItem autoPlay={true} tutuVideo={tutuAd} />
                    }
                    <MediaGridItem mediaListItem={mediaListItem} hrefPrefix={hrefPrefix} />
                    </React.Fragment>
                )
            })}
        </div>
    )
}