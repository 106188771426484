import { getTutuModelsUrl, TutuModelVideo } from "@jconradi/tutu-porn-components/services/api/tutu-models/tutu-models"
import Link from 'next/link'
import Image from 'next/image';
import HoverVideoPlayer from "react-hover-video-player";
import { Spinner } from "../../Spinner/Spinner";
import { useRouter } from "next/router";

export interface TutuMediaGridItem {
    tutuVideo: TutuModelVideo;
    autoPlay: boolean;
}
export const TutuMediaGridItem = ({ tutuVideo, autoPlay = true }: TutuMediaGridItem) => {
    const router = useRouter();
    const href = getTutuModelsUrl(`/videos/${tutuVideo.slug}`, router.locale);

    return (
        <a href={href} className="flex flex-col relative">
            <div
                className="rounded-md bg-base-300 aspect-square h-full relative w-full">

                {autoPlay &&
                    <video src={tutuVideo.thumbUrl} className="absolute inset-0 w-full h-full hover:ring-[4px] hover:ring-primary rounded-md" autoPlay playsInline loop muted preload="none" />
                }
                {!autoPlay &&
                    <HoverVideoPlayer
                        sizingMode="container"
                        className="absolute  inset-0 w-full h-full hover:ring-[4px] hover:ring-primary rounded-md"
                        videoClassName="object-contain"
                        videoSrc={tutuVideo.thumbUrl}
                        preload="none"
                        loop={true}
                        restartOnPaused={true}
                        unloadVideoOnPaused={true}
                        muted={true}
                        pausedOverlay={
                            <Image priority className='object-contain w-full h-full'
                            width={100}
                            height={100}                            
                            src={tutuVideo.coverUrl} alt="" />
                        }
                        loadingOverlay={
                            <div className="flex w-full h-full items-center justify-center">
                                <Spinner />
                            </div>
                        }
                    />
                }

            </div>
            <p 
                className="max-h-[36px] text-clip break-words w-full block text-sm overflow-hidden leading-[18px]">
                {tutuVideo.name}
            </p>
        </a>
    )
}