import Link from "next/link";
import { MediaListItem } from "@jconradi/tutu-porn-api-client/src/users"
import { BunnyCdnImage } from "../../Image/BunnyCdnImage";

export interface MediaGridFooterProps {
    media: MediaListItem;
}
export const MediaGridFooter = ({ media }: MediaGridFooterProps) => {
    return (
        <div className="flex flex-row mt-2 gap-x-2 ">

            <Link href={`/users/${media.user.username}`} className="bg-base-100 w-[24px] h-[24px] mt-1 relative overflow-hidden rounded-sm flex-none">
                <BunnyCdnImage priority width={36} height={36} src={media.user.avatar} alt="User Avatar" className="absolute inset-0 w-full h-full" />
            </Link>

            <div className="flex flex-col flex-1 w-full overflow-hidden">
                {media.title &&
                    <Link className="max-h-[36px] text-clip break-words w-full block text-sm overflow-hidden leading-[18px]" href={`/watch/${media.slug}`}>
                        {media.title}
                    </Link>
                }
                <Link href={`/users/${media.user.username}`} className="text-sm text-gray-300">
                    {media.user.name || media.user.username}
                </Link>
            </div>

        </div>
    )
}