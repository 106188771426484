import { IconContainer } from "../../Buttons/IconContainer"
import PhotosIcon from "../../../public/images/icons/photo.svg";

export const PhotosBadge = ({ amount }: { amount?: number }) => {
    if (amount === 0) {
        return null;
    }

    return (
        <IconContainer icon={<PhotosIcon className="w-4 h-4 text-white overlay-badge" />} responsiveText={false}  labelClassName="text-sm overlay-badge" label={amount?.toLocaleString() || ''} />
    )
}