import {
    useQuery,
  } from '@tanstack/react-query'
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { getMedia, MediaSearchQuery, searchMedia } from '@jconradi/tutu-porn-api-client/src/media';

export const useMediaSearch = (queryArgs: MediaSearchQuery, enabled: boolean = true) => {
    const { isLoggedIn } = useContext(AuthContext);

    const query = useQuery({
        queryKey: ['media', queryArgs],
        queryFn: async () => {
            const resp = await searchMedia(queryArgs);

            return resp.data;
        },
        useErrorBoundary: true,
        enabled,
        keepPreviousData: false
    });

    return query;
}