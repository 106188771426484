import { IconContainer } from "../../Buttons/IconContainer"
import VideoIcon from "../../../public/images/icons/video.svg";

export const VideosBadge = ({ amount }: { amount?: number }) => {
    if (amount === 0) {
        return null;
    }

    return (
        <IconContainer icon={<VideoIcon className="w-4 h-4 text-white overlay-badge" />} labelClassName="text-sm overlay-badge" responsiveText={false} label={amount?.toLocaleString() || ''} />
    )
}