import { MediaListItem as MediaListItemResp } from "@jconradi/tutu-porn-api-client/src/users"
import { useInView } from 'react-intersection-observer';
import { Transcoding } from "../Transcoding/Transcoding";
import Link from "next/link";
import { MediaType } from "@jconradi/tutu-porn-api-client/src/media";
import HoverVideoPlayer from 'react-hover-video-player';
import { Spinner } from "../../Spinner/Spinner";
import { ViewsBadge } from "../Badges/ViewsBadge";
import { GalleryMediaGridItem } from "./GalleryMediaGridItem";
import { StatsBadge } from "../Badges/StatsBadge";
import { BunnyCdnImage } from "../../Image/BunnyCdnImage";
import { VerifiedBadge } from "../Badges/VerifiedBadge";
import { MediaGridFooter } from "./MediaGridFooter";
import { DurationBadge } from "../Badges/DurationBadge";
export interface MediaGridItemProps {
    mediaListItem: MediaListItemResp;
    hrefPrefix?: string;
}
export const MediaGridItem = ({ mediaListItem, hrefPrefix = '' }: MediaGridItemProps) => {
    const imgSrc = mediaListItem.urls.poster;
    const { ref: inViewRef, inView } = useInView({
        triggerOnce: false,
        rootMargin: '1000px 0px',

    });

    const path = `${hrefPrefix}/watch/${mediaListItem.slug}`;

    return (
        <div className="flex flex-col w-full">
            <Link ref={inViewRef} href={path}>
                <div
                    className="hover:ring hover:ring-[4px] hover:ring-primary rounded-md bg-base-300 aspect-square h-full ">
                    <div style={{
                        'WebkitMaskImage': 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC)'
                    }} className="rounded-md h-full w-full relative overflow-hidden">
                        {inView && mediaListItem.isTranscoding &&
                            <Transcoding />
                        }
                        {inView && !mediaListItem.isTranscoding &&
                            <>
                                {mediaListItem.mediaType === MediaType.Photo &&
                                    <>
                                        <BunnyCdnImage query={{
                                            aspect_ratio: '1:1'
                                        }}  width={200} height={200} className="object-cover absolute inset-0 w-full h-full"
                                            src={imgSrc} alt={`Photo for ${mediaListItem.slug}`} />
                                        <ViewsBadge media={mediaListItem} />
                                        <StatsBadge media={mediaListItem} />
                                        {mediaListItem.user.isVerified && <VerifiedBadge /> }
                                    </>
                                }
                                {mediaListItem.mediaType === MediaType.Gallery &&
                                    <GalleryMediaGridItem media={mediaListItem} />
                                }
                                {mediaListItem.mediaType === MediaType.Video &&
                                    <HoverVideoPlayer
                                        sizingMode="container"
                                        className="absolute  inset-0 w-full h-full"
                                        videoClassName="object-cover"
                                        videoSrc={mediaListItem.urls.sd!}
                                        preload="none"
                                        loop={true}
                                        restartOnPaused={true}
                                        unloadVideoOnPaused={true}
                                        muted={true}
                                        pausedOverlay={
                                            <>
                                                <BunnyCdnImage query={{
                                                    aspect_ratio: '1:1'
                                                }} width={200} height={200} className="object-cover absolute inset-0 w-full h-full"
                                                    src={imgSrc} alt={`Photo for ${mediaListItem.slug}`} />
                                                <ViewsBadge media={mediaListItem} />
                                                <StatsBadge media={mediaListItem} />
                                                <DurationBadge durationInSeconds={mediaListItem.info.duration || 0} />
                                                {mediaListItem.user.isVerified && <VerifiedBadge /> }
                                            </>
                                        }
                                        loadingOverlay={
                                            <div className="flex w-full h-full items-center justify-center">
                                                <Spinner />
                                            </div>
                                        }
                                    />
                                }
                            </>
                        }
                    </div>
                </div>
            </Link>

            <MediaGridFooter media={mediaListItem} />
        </div>
    )
}