import { useState } from "react";
import { useGallery } from "../../../hooks/useGallery";
import { MediaType } from "@jconradi/tutu-porn-api-client/src/media";
import { MediaListItem } from "@jconradi/tutu-porn-api-client/src/users";
import { BunnyCdnImage } from "../../Image/BunnyCdnImage";
import { StatsBadge } from "../Badges/StatsBadge";
import { VerifiedBadge } from "../Badges/VerifiedBadge";
import { ViewsBadge } from "../Badges/ViewsBadge";

export interface GalleryMediaGridItemProps {
    media: MediaListItem;
}
export const GalleryMediaGridItem = ({ media }: GalleryMediaGridItemProps) => {
    const galleryQuery = useGallery(media.gallery!);
    const [ index, setIndex ] = useState(0);
    const [ timerId, setTimerId ] = useState<any>();
    const [ loading, setLoading ] = useState(false);
    const [ isHover, setIsHover ] = useState(false);

    const videos = galleryQuery.data?.data.media.filter(m => m.mediaType === MediaType.Video);
    const photos = galleryQuery.data?.data.media.filter(m => m.mediaType === MediaType.Photo);

    const onHover = () => {
        const id = setInterval(() => {
            setIndex((index) => (index + 1) % (galleryQuery.data?.data.media.length || 0));
        }, 2000);

        setTimerId(id);
        setIsHover(true);
    }

    const onHoverEnd = () => {
        clearInterval(timerId);
        setTimerId(undefined);
        setIndex(0);
        setIsHover(false);
    }

    let imgSrc = (isHover && !galleryQuery.isLoading) ?
         galleryQuery.data?.data.media[index].urls.poster! : media.urls.poster!;

    return (
        <>
        <BunnyCdnImage onMouseEnter={onHover} onMouseLeave={onHoverEnd} onTouchEnd={() => onHoverEnd()} onTouchStart={() => onHover()}   query={{
            aspect_ratio: '1:1'
        }}
         width={200} height={200} className="object-cover absolute inset-0 w-full h-full" src={imgSrc}  alt={`Photo for ${media.slug}`} />
        <ViewsBadge media={media} />
        <StatsBadge media={media} videos={videos?.length} photos={photos?.length} />
        {media.user.isVerified && <VerifiedBadge /> }
        </>
    )
}