import { IconContainer } from "../../Buttons/IconContainer"
import ViewsIcon from "../../../public/images/icons/views.svg";
import { PhotosBadge } from "./PhotosBadge";
import { VideosBadge } from "./VideosBadge";
import { MediaListItem } from "@jconradi/tutu-porn-api-client/src/users";

export const StatsBadge = ({ media, videos, photos }: { media: MediaListItem, videos?: number, photos?: number}) => {
    return (
        <div className="flex flex-col items-start justify-start gap-y-1 absolute bottom-0 right-0 m-1 ">
            {(media.mediaType === "photo" || media.mediaType === "gallery") &&
                <>
                    <PhotosBadge amount={photos} />
                </>
            }
            {(media.mediaType === "video" || media.mediaType === "gallery") &&
                <>
                    <VideosBadge amount={videos} />
                </>
            }
        </div>
    )
}